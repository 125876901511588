import { fullHeightMobile } from '@m/styled/mixins';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    background-color: ${({ theme }) => theme.colors.grey1};
    color: ${({ theme }) => theme.colors.grey7};
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    ${fullHeightMobile}
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-drag: none;
    user-select: none;
    overflow: hidden;
  }

  body {
    font-family: "Neue Montreal", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    font-feature-settings: normal;
    font-variant-numeric: normal;
    background-color: inherit;
    & *,
    & *::before,
    & *::after {
      font-feature-settings: inherit;
      font-variant-numeric: inherit;
      font-family: inherit;
      box-sizing: inherit;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  input,
  textarea,
  select,
  button {
    appearance: none;
    all: initial;
    font: inherit;
    color: inherit;
  }
`;
