import { useCallback, useEffect, useState } from 'react';

import { heapTrackProperties } from '@m/utils/heap';
import { usePrevious } from 'react-use';

import { ModalHeading } from 'apps/kiosk/components';
import { PlateForm, ValidationInfo } from 'apps/kiosk/components/Validation';
import { ValidationService } from 'apps/kiosk/services';

import { InputContainerBox } from './PlateInputContainer.styled';

import type { KioskValidationDetail, ValidationFailureReason } from 'apps/kiosk/types';

type PlateInputContainerProps = {
  shortCode: string;
  validationInfo: KioskValidationDetail | null;
  active?: boolean;
  completed?: boolean | null;
  onSuccess: () => void;
  onFailure: (licensePlateText: string, reason: ValidationFailureReason) => void;
  onClickUnknownPlate: () => void;
};

export function PlateInputContainer({
  shortCode,
  validationInfo,
  active,
  completed,
  onSuccess,
  onFailure,
  onClickUnknownPlate,
}: PlateInputContainerProps) {
  const prevCompleted = usePrevious(completed);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (licensePlateText: string) => {
      setLoading(true);
      heapTrackProperties('Submit: License plate validation form', {
        'Date/Time (UTC)': new Date().toUTCString(),
        timestamp: Date.now(),
        'License Plate': licensePlateText,
      });
      const { success, error } = await ValidationService.validateVisit({
        shortCode,
        licensePlateText,
      });

      setSubmitted(true);
      setLoading(false);

      if (success) {
        onSuccess();
        heapTrackProperties('Validated: License plate validation success', {
          'Date/Time (UTC)': new Date().toUTCString(),
          timestamp: Date.now(),
          'License Plate': licensePlateText,
        });
      } else {
        const errorMessage = `${error}`;

        const cantFindVisit = errorMessage.includes('No visit open');
        const alreadyValidated = errorMessage.includes('already been applied');

        onFailure(licensePlateText, { cantFindVisit, alreadyValidated });

        if (cantFindVisit) {
          heapTrackProperties("Error: Can't find visit", {
            'Date/Time (UTC)': new Date().toUTCString(),
            timestamp: Date.now(),
            'License Plate': licensePlateText,
          });
        }
        if (alreadyValidated) {
          heapTrackProperties('Error: Validation already applied', {
            'Date/Time (UTC)': new Date().toUTCString(),
            timestamp: Date.now(),
            'License Plate': licensePlateText,
          });
        }
      }
    },
    [onFailure, onSuccess, shortCode],
  );

  useEffect(() => {
    if (submitted && prevCompleted === false && completed === null) {
      setSubmitted(false);
    }
  }, [completed, prevCompleted, submitted]);

  return (
    <InputContainerBox completed={completed === true}>
      <ModalHeading>Enter your license plate number</ModalHeading>
      <ValidationInfo validation={validationInfo} />
      <PlateForm
        active={active}
        loading={loading}
        submitted={submitted}
        onSubmit={handleSubmit}
        onClickUnknownPlate={onClickUnknownPlate}
      />
    </InputContainerBox>
  );
}

export default PlateInputContainer;
