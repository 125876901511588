import { Box } from '@m/components/uikit';

import { ReactComponent as AlertIcon } from 'apps/kiosk/assets/svg/alert.svg';
import { AlertModal } from 'apps/kiosk/components/Modals/AlertModal';
import {
  ModalHeadingLarge,
  MessageText,
  ModalButton,
} from 'apps/kiosk/components/Modals/Modals.styled';

type CantFindVisitProps = {
  show?: boolean;
  onCancel?: () => void;
  onEditPlate?: () => void;
  licensePlateText: string;
};

export function CantFindVisit({
  show,
  onCancel,
  onEditPlate,
  licensePlateText,
}: CantFindVisitProps) {
  return (
    <AlertModal show={show} onCancel={onCancel} icon={<AlertIcon />}>
      <ModalHeadingLarge>We can&apos;t find your parking visit</ModalHeadingLarge>
      <MessageText>
        <span>Your license plate&nbsp;</span>
        <span>{licensePlateText}</span>
        <span>&nbsp;does not seem to be tied to an&nbsp;</span>
        <span>active parking visit at this location.</span>
      </MessageText>
      <MessageText>Please confirm your license plate is correct and try again</MessageText>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginTop="8px"
      >
        <ModalButton
          onClick={onEditPlate}
          width="282px"
          border="2px solid rgba(86, 103, 137, 0.26)"
          color="rgba(21, 25, 32, 0.8)"
          marginRight="6px"
        >
          Edit Plate
        </ModalButton>
        <ModalButton
          width="282px"
          onClick={onCancel}
          backgroundColor="grey7"
          color="grey1"
          marginLeft="6px"
        >
          Close
        </ModalButton>
      </Box>
    </AlertModal>
  );
}
