import { useLayoutEffect, useMemo } from 'react';

import Countdown from '@m/components/Countdown';
import throttle from 'lodash/throttle';

import { Banner } from 'apps/kiosk/components/Banner';

import {
  IdleMessageBox,
  IdleProgressBar,
  IdleMessage,
  IdleMessageText,
  CountdownMessage,
  ExtendTimeButton,
  WarningIcon,
} from './IdleBanner.styled';

type IdleBannerProps = {
  show: boolean;
  idleTimeoutDuration?: number;
  onIdleTimeout: () => void;
  onUserInteract: () => void;
};
export function IdleBanner({
  show,
  idleTimeoutDuration = 10,
  onIdleTimeout,
  onUserInteract,
}: IdleBannerProps) {
  const handleUserInteract = useMemo(
    // Using throttle to prevent duplicate calls
    // if both mouse/touch events fire
    () =>
      throttle(
        (e: MouseEvent | TouchEvent) => {
          if (show) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            onUserInteract();
          }
        },
        100,
        { leading: true, trailing: false },
      ),
    [onUserInteract, show],
  );

  useLayoutEffect(() => {
    if (show) {
      // `capture = true` so that event handler has first priority
      // over other event handlers
      // `passive = false` to enable preventDefault(), stopPropagation()
      window.addEventListener('mousemove', handleUserInteract, { capture: true, passive: false });
      window.addEventListener('touchstart', handleUserInteract, { capture: true, passive: false });
    } else {
      window.removeEventListener('mousemove', handleUserInteract, { capture: true });
      window.removeEventListener('touchstart', handleUserInteract, { capture: true });
    }
    return () => {
      window.removeEventListener('mousemove', handleUserInteract, { capture: true });
      window.removeEventListener('touchstart', handleUserInteract, { capture: true });
    };
  }, [handleUserInteract, show]);

  return (
    <Banner show={show}>
      <IdleMessageBox>
        <IdleProgressBar show={show} duration={idleTimeoutDuration} />
        <WarningIcon />
        <IdleMessage>
          <IdleMessageText>Need more time?</IdleMessageText>
          {show ? (
            <Countdown
              duration={idleTimeoutDuration}
              offsetStart={250}
              onComplete={onIdleTimeout}
              renderCountdown={(remaining) => (
                <CountdownMessage>
                  {`This screen will close in ${remaining} seconds…`}
                </CountdownMessage>
              )}
            />
          ) : (
            <CountdownMessage>&nbsp;</CountdownMessage>
          )}
        </IdleMessage>
        <ExtendTimeButton>I&apos;m still here</ExtendTimeButton>
      </IdleMessageBox>
    </Banner>
  );
}
